import Accordion from "../components/UI/Accordion";
import PageHeader from "../components/UI/PageHeader";
import DocumentBanner from "../assets/images/banners/documents-banner.webp"

const Documents = () => {
    const documentsArr = [
        {
            title: "C-Capsule Code",
            fileSrc: "/documents/C-CapsuleCodeCDR_v1.1_Consultation.docx",
            aboutDocument:
                "For Carbon Dioxide Removal (CDR) - Version 1.1",
        },
        {
            title: "C-Capsule Code",
            fileSrc: "/documents/C-CapsuleCodeCDR_v1.0.pdf",
            aboutDocument:
                "For Carbon Dioxide Removal (CDR) - Version 1.0",
        },
        {
            title: "QP-01: Methodology Approval",
            fileSrc: "/documents/QP-01-C-Capsule-Methodology-Approval-Procedure-v2.0.pdf",
        },
        {
            title: "QP-02: Guidelines for Verification Authorities",
            fileSrc: "/documents/QP-02-Guidelines-for-Verification-Authorities.pdf",
        },
        {
            title: "SD-01: Methodology Guidelines",
            fileSrc: "/documents/SD-01-C-Capsule-Methodology-Requirements-v1.0.pdf",
        },
        {
            title: "SD-02: Eligible Removal Types",
            fileSrc: "/documents/SD-02-C-Capsule-Removal-Types-and-Codes-v1.0.pdf",
        },
        {
            title: "SF-01: Market Entity Application",
            fileSrc: "/documents/SF-01-C-Capsule-Market-Entity-Application-v1.0.pdf",
        },
        {
            title: "SF-02: Facility Registration",
            fileSrc: "/documents/SF-02-C-Capsule-Facility-Registration-v1.0.pdf",
        },
        {
            title: "SF-03: Change Request",
            fileSrc: "/documents/SF-03-C-Capsule-Change-Request-v1.0.pdf",
        },
        {
            title: "SF-04: Complaint",
            fileSrc: "/documents/SF-04-C-Capsule-Complaint-v1.0.pdf",
        },
        {
            title: "SF-05: Methodology Concept Note: Template",
            fileSrc: "/documents/SF-05-C-Capsule_Methodology-Concept-Note-Template.docx",
        },
        {
            title: "SF-06 Issue Request Form",
            fileSrc: "/documents/SF-06-Issue-Request-Form.pdf",
        },
        {
            title: "SF-07: Event of Carbon Default (EOCD) Report",
            fileSrc: "/documents/Event-of-Carbon-Default-Report-v1.0.docx",
        },
        {
            title: "SF-08: Verification Authorities Application Form",
            fileSrc: "/documents/SF-08-Verification-Authorities-Application-Form.docx",
        },
        {
            title: "Distributed Biochar Methodology",
            fileSrc: "/documents/Distributed_Biochar_Methodology_v1.0.pdf",
            aboutDocument:
                "Digital MRV for distributed Biochar production and Carbon Dioxide Removal (CDR) system",
        }
    ];

    return (
        <>
            <PageHeader headerText="Documents" headerImg={DocumentBanner} />
            <div className="container pb-20">

                <section className="p-6 sm:p-3">
                    {documentsArr.map((doc, index) => (
                        <Accordion key={`doc-${index}-${doc.title}`} subText={doc.aboutDocument} mainText={doc.title} doc={doc} />
                    ))}
                </section>
            </div>
        </>

    );
};

export default Documents;